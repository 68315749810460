@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  /* background-image: url("http://www.tatebraeckelart.com/uploads/1/7/8/7/17870845/agua-color-1_orig.png"); */
}
.headerH1 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 3.5rem; /* Large font size */
  font-weight: 700; /* Bold font weight */
  color: #fff; /* White text color */
}

/* Navigation buttons */
nav ul li button {
  background-color: transparent; /* Transparent background */
  border: none; /* Remove border */
  color: #fff; /* White text color */
  font-family: 'Roboto', sans-serif; /* Use Roboto font */
  font-size: 1.2rem; /* Larger font size */
  padding: 10px 20px; /* Padding */
  cursor: pointer; /* Show pointer cursor on hover */
  transition: all 0.3s ease; /* Smooth transition on hover */
}

nav ul li button:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Light gray background on hover */
  border-radius: 5px; /* Add border radius on hover */
}

.section {
  height: 800px;
  display: flex;
  justify-content: center; 
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #fff;
  transition: all 0.3s ease;
  padding-bottom: 90px;
  padding-top: 20px;
  margin-bottom: 0px; 
  margin-top: 0px; 
}
.section-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* Add margin to separate the title from the project cards */
}

.project-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: right; /* Align the project cards to the right */
  margin-top: 20px;
   /* Add margin to separate the project cards from the title */
}


/* Adjust top margin for sections other than the developer section */
.designer,
.creator,
.about {
  margin-top: 20px; /* Adjust the top margin as needed */
}

.section h2 {
  margin-top: 50px; /* Adjust the top margin of the section title */
}

  .developer {
    background-image: url("/public/water_bg.jpg");
      background-repeat: no-repeat;
      background-size:   cover;
  }

.designer {
  background-image: url("/public/blue_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.creator {
  background-image: url("/public/pink_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.about {
  /* margin-top: 0px;
  padding: 0px;
  background-color: #d2a8d8;  */
  background-image: url("/public/pink_water_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.button {
  background-color: transparent;
  border: 2px solid #fff;
  padding: 10px 20px;
  font-size: 38px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button:hover {
  transform: scale(0.95);
}

.project-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Distribute space evenly between cards */
}

.project-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin: 0px;
  max-width: 400px; /* Adjust the maximum width as needed */
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.project-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.project-description {
  font-size: 16px;
  margin-bottom: 20px;
  color: #666;
}

.video-container {
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.project-video {
  width: 100%;
  height: auto;
}

.project-links {
  display: flex;
  justify-content: space-between;
}

.github-link,
.details-link {
  color: #007bff;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

.github-link:hover,
.details-link:hover {
  color: #0056b3;
}

.App-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000; /* Ensure header stays on top */
  transition: top 0.3s; /* Added missing 's' to the transition duration */
}

/* Ensure header is hidden when scrolled down */
.App-header.hidden {
  top: -100px; /* Adjust this value as needed based on your header height */
}

.App-header img {
  width: 50px; /* Adjust width of the logo */
  margin-left: 20px; /* Add margin to the left of the logo */
}

.App-header h1 {
  color: #fff; /* White text color */
  font-size: 2rem; /* Larger font size */
}

.App-header nav ul {
  list-style-type: none; /* Remove bullet points from the list */
  padding: 0; /* Remove default padding */
}

.App-header nav ul li {
  display: inline-block; /* Display items horizontally */
  margin-right: 20px; /* Add margin between items */
}

.App-header nav ul li button {
  background-color: transparent; /* Transparent background */
  border: none; /* Remove border */
  color: #fff; /* White text color */
  font-size: 1rem; /* Font size */
  padding: 10px 20px; /* Padding */
  cursor: pointer; /* Show pointer cursor on hover */
  transition: all 0.3s ease; /* Smooth transition on hover */
}

.App-header nav ul li button:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Light gray background on hover */
  border-radius: 5px; /* Add border radius on hover */
}
