.scroll-top-btn {
    position: fixed; 
    bottom: 20px; 
    right: 20px; 
    background-color: #333;
    color: #fff; 
    border: none; 
    border-radius: 50%; 
    width: 50px; 
    height: 50px; 
    font-size: 24px; 
    cursor: pointer; 
    transition: background-color 0.3s ease; 
}

  .scroll-top-btn span {
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
  }