@keyframes curveUpLeft {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-20px, -20px);
  }
  50% {
    transform: translate(-30px, -40px);
  }
  75% {
    transform: translate(-40px, -70px);
  }
  100% {
    transform: translate(-50px, -110px);
  }
}

.developer-project-card {
  background-color: #0d0c0c; 
  border-radius: 10px;
  box-shadow: 0 10px 8px rgba(251, 250, 250, 0.1);
  padding: 20px;
  max-width: 300px;
  margin: 15px auto;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  margin: 25px;
  opacity: .7;
  height: 400px;
}

.developer-project-card:hover {
  background-color: rgba(248, 249, 250, 0.7); 
  animation: curveUpLeft 0.2s forwards;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  color: #98509e; 
  opacity: 1.0;

}

.developer-project-card:hover .developer-project-title,
.developer-project-card:hover .developer-project-description {
  color: #154343; 
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
}

.developer-project-title {
  font-size: 24px;
  color: #54b7f5;
  margin-bottom: 10px;
}

.developer-project-description {
  font-size: 16px;
  color: #fafafa;
  margin-bottom: 20px;
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
 
}

.developer-video-container {
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.developer-project-video {
  width: 100%;
  height: auto;
}

.developer-project-links {
  display: flex;
  justify-content: center;
  width: 100%;
  color: #d400ff;
}

.developer-github-link,
.developer-details-link {
  color: #d400ff;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

.developer-github-link:hover,
.developer-details-link:hover {
  color: #faf9fb;
}
