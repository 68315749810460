@keyframes moveLeft {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-20px, 0);
  }
  50% {
    transform: translate(-40px, 0);
  }
  75% {
    transform: translate(-70px, 0);
  }
  100% {
    transform: translate(-110px, 0);
  }
}

.design-project-card {
  position: relative;
  width: 100%;
  max-width: 350px;
  background-color: rgba(199, 198, 198, 0.1);
  border: 1px solid #ccc;
  border-radius: 20px;
  margin: 20px auto; /* Center the card horizontally */
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center elements horizontally */
  justify-content: center;
  text-align: center; 
  margin: 15px;

}

.design-project-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
  animation: moveLeft 0.2s forwards;
 color: rgb(251, 251, 251);
  z-index: 5;
}

.design-project-card img {
  width: 110%;
  height: auto;
}

.design-project-card .content {
  padding: 30px; 
}

.design-project-card h3 {
  margin-top: 20px;
  font-size: 28px;
  margin-bottom: 20px;
}

.design-project-card p {
  margin-bottom: 20px;
  margin: 20px;
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
}

.design-project-card .project-links {
  display: flex;
  justify-content: center; 
  width: calc(100% - 40px); 
  padding: 20px; 
}

.design-project-card .project-links a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  transition: color 0.3s ease;
}

.design-project-card .project-links a:hover {
  color: #f9f4f4;
}

.design-break {
  background-color: rgba(170, 51, 106, 0.5); 
  height: 3px;
  width: 60%; 
  margin: 10px 0; 
}

