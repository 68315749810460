.about-content {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0px 40px 0px 0px !important;
  width: 1350px;
  justify-content: center;
  padding: 0px;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 0px;
}

.about-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(204, 204, 204, 0.5);
  z-index: -1; 
  border-radius: 30px;
}

.about-title {
  font-size: 2.5rem;
  color: #080808;
  padding: 0px;

}

.about-description {
  width: 500px;
  font-size: 1.2rem;
  color: #292d53;
  margin-bottom: 0px;
}

.tech-skills {
  margin-left: 100px;
  align-items: flex-start;
  justify-content: center;
}

.tech-skills-title {
  font-size: 2.5rem;
  color: #160612;
  margin-bottom: 10px;
}

.tech-skills-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding:  0px;
}

.tech-skill {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 5px;
}

.tech-skill:hover {
  color: #fdfbfb;
  cursor: pointer;
}

.tech-skills-break{
  background-color: rgba(170, 51, 106, 0.5); 
  height: 3px;
  width: full;
  z-index: -1; 
}

.soft-skills {
  margin-left: 20px;
  align-items: flex-start;
  justify-content: center;
}

.soft-skills-title {
  font-size: 2.5rem;
  color: #000000;
  margin-bottom: 10px;
}

.soft-skills-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 10px;
}

.soft-skill {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 10px;
}

.soft-skill:hover {
  color: #fdfbfb;
  cursor: pointer;
}

.soft-skills-break{
  background-color: rgba(170, 51, 106, 0.5); 
  height: 3px;
  width: full;
  z-index: -1; 
}
.links {
  margin-left: 20px;
  align-items: flex-start;
  justify-content: center;
  padding: 15px;
}

.links-title {
  font-size: 2.5rem;
  color: #030303;
  margin-bottom: 70px;
}

.links-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 15px;
}

.link-item {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 15px;
} 

.link-item  :hover {
  color: #8b179a;
  cursor: pointer;
}

.links-break{
  background-color: rgba(170, 51, 106, 0.5); 
  height: 3px;
  width: full;
  z-index: -1; 
}