.creator-project-card {
  position: relative;
  width: 300px;
  height: 300px;
  overflow: hidden;
  transition: transform 0.3s ease; 
  border-radius: 10px; 
  margin: 20px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.creator-project-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.creator-project-card .overlay {
  position: absolute;
  bottom: 0; 
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease; 
}

.creator-project-card:hover .overlay {
  opacity: 1; 
}

.creator-project-card .overlay-content {
  padding: 20px; 
  text-align: center; 
}

.creator-project-card .overlay h3 {
  margin-top: 20px; 
  margin-bottom: 10px; 
  font-size: 24px;
  color: rgb(227, 233, 109);
}

.creator-project-card .overlay p {
  margin-bottom: 30px;
  margin: 20px;
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
}
